<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Overview</div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              hide-default-footer
            >
              <template v-slot:[`item.project_name`]="{ item }">
                <router-link
                  :to="{
                    name: 'projectTasks',
                    params: { id: item.id },
                  }"
                >
                  {{ item.project_name }}
                </router-link>
              </template>
            </v-data-table>
          </div>
        </section>
        <div class="conOfPagination pr-5 pt-5">
          <v-pagination
            v-model="pagination.current_page"
            :total-visible="7"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
export default {
  data: () => ({
    pagination: {
      current_page: 1,
    },
    isLoading: false,

    headers: [
      { text: "Company", value: "client_company" },
      { text: "Project Name", value: "project_name" },
      { text: "Client Name", value: "client_name" },
      { text: "Project Country", value: "project_country" },
      { text: "Project Timeline", value: "project_timeline" },
      { text: "Sales Name", value: "sales_in_charge" },
      { text: "Total Hours", value: "total_hours" },
    ],
    listData: [],
  }),
  computed: {},
  components: { EmptyState },
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      apiServices
        .get(
          `country-projects?per_page=7&page=${page}&country_id=${this.$route.params.id}`
        )
        .then((res) => {
          if (res) {
            if (res.data.length > 0) {
              this.isLoadingSearch = false;
              this.listData = res.data;
              this.pagination = res.meta;
            }
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
